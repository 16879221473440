import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useRef, useState } from 'react';
import Layout from "../components/layout";
import Seo from '../components/seo/seoall'
import emailjs from 'emailjs-com'

const ContactPage = () => {



  const [show, setShow]= useState('mt-5 text-center d-none')


  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_iut7zmn', 'template_c4trnrh', form.current, 'eCPlbRGx_lyZYNu-a')
      .then((result) => {
          setShow('mt-5 text-center');
      }, (error) => {
          console.log(error.text);
      });
  };






  const isBrowser = typeof window !== "undefined"

  var sitename = process.env.GATSBY_URL.replace('https://','').replace('.com','')
  if (isBrowser){
    sitename = window.location.hostname
  }

  return (
    <div>
      <Layout>

        <div className="container-fluid">
          <div className="row justify-content-center sec-color">
                  <h1 className="text-center pt-5 pb-1">Contact Us</h1>
                  <p className="col-10 col-md-6 text-center pb-3 contact-text">Our team is here to help with any questions you may have. Please email us at <span className="bold pt-1">contact@{sitename}.com</span></p>
          </div>
          <p className={show}>Message sent successfully!</p>
          <div className="single-post py-4 my-4 justify-content-center d-flex">
            

            <form className="contact-form" ref={form} onSubmit={sendEmail}>
              <div class="form-group">
                <label for="namefield">Name<span className="text-danger">*</span></label>
                <input type="name" class="form-control sec-color rounded-0 mb-3 mt-2" id="namefield"/>
              </div>
              <div class="form-group">
                <label for="emailfield">Email<span className="text-danger">*</span></label>
                <input type="email" class="form-control sec-color rounded-0 mb-3 mt-2" id="emailfield"/>
              </div>
              <div class="form-group">
                <label for="messagefield">Message<span className="text-danger">*</span></label>
                <textarea class="form-control sec-color rounded-0 mb-3 mt-2" id="messagefield" rows="6"></textarea>
              </div>
              <button type="submit" class="btn mt-2 submit-button rounded-0 px-5">Submit</button>
            </form>




          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ContactPage;
